<template>
  <h2>Opis utakmice</h2>
  <p>
    Utakmica Highlight Games NBA Last 60 je jedinstvena softverska simulacija nasumično odabranih najzanimljivijih
    trenutaka s košarkaških utakmica za stvaranje šansi za igranje.
  </p>
  <h2>Opis i duljina utakmice</h2>
  <p>
    NBA Last 60 Scheduled sadrži niz stranica koje će biti vođene vremenom, omogućujući krajnjem korisniku da se
    informira o važnim činjenicama s utakmice i prilikama za klađenje u raznovrsnim područjima. Svaka utakmica u NBA
    Last 60 traje otprilike od 3 minute do 3 minute i 30 sekundi od uvoda do konačnog rezultata.
  </p>
  <p>
    Izbor najzanimljivijih NBA događaja statistički je neovisan, nasumičan i nepredvidiv, a generira ga Generator
    slučajnih brojeva – RNG.
  </p>
  <h2>Područja klađenja</h2>
  <h3>Sveukupni pobjednik</h3>
  <p>
    Igrač se može kladiti na ukupnog pobjednika utakmice na temelju i početne rezultatske linije i rezultatske linije u
    Last 60 zbrojeno. Ukupni rezultat nikada ne može biti neriješen u NBA Last 60.
  </p>
  <h3>Pobjednik u Last 60</h3>
  <p>
    Kladite se na pobjedničku momčad rezultatske linije u Last 60. Ova rezultatska linija u Last 60 nikada ne može biti
    neriješena. 
  </p>
  <h3>Obje ekipe postižu više od 2,5 poena</h3>
  <p>Igrač se može kladiti da će i domaćin i gost postići najmanje preko 2,5 poena na jednoj utakmici u NBA Last 60.</p>
  <h3>Obje ekipe postižu više od 3,5 poena</h3>
  <p>I domaćin i gost trebaju postići najmanje preko 3,5 poena na jednoj utakmici u NBA Last 60.</p>
  <h3>Ukupo trica u Last 60</h3>
  <p>
    Predvidite ukupan broj postignutih trica na utakmici, pri čemu možete kombinirati trice koje postigne domaćin ili
    gost, što će se uračunati u ukupan broj trica. Maksimalan broj trica na utakmici može biti 6.
  </p>
  <h3>Tim 1 postiže u Last 60</h3>
  <p>Tim 1 doma ili u gostima osvaja bod u NBA Last 60.</p>
  <h3>Razlika u broju poena u Last 60</h3>
  <p>Predvidite razliku u broju poena koje postigne pobjednički tim na Last 60 utakmici, Razlika u broju poena.</p>
  <h3>Domaćin do 4 poena u Last 60</h3>
  <p>
    Tim 1 doma ili u gostima postiže 4 poena na utakmici Last 60. Ishod „nijedan tim“ nije valjan odabir za ovo
    područje. 
  </p>
  <h3>Domaćin Više/Manje od 4,5 poena u Last 60</h3>
  <p>Poeni koje osvoji Tim 1 (domaći) u Last 60 bit će više ili manje od 4,5.</p>
  <h3>Gost Više/Manje od 4,5 poena u Last 60</h3>
  <p>Poeni koje osvoji Tim 2 (gost) u Last 60 bit će više ili manje od 4,5.</p>
  <h3>Domaćin Više/Manje od 5,5 poena u Last 60</h3>
  <p>Poeni koje osvoji Tim 1 (domaći) u Last 60 bit će više ili manje od 5,5.</p>
  <h3>Gost Više/Manje od 5,5 poena u Last 60</h3>
  <p>Poeni koje osvoji Tim 2 (gost) u Last 60 bit će više ili manje od 5,5.</p>
  <h3>Pobjednik u Last 60 i više/manje od 11,5 poena</h3>
  <p>
    Predvidite pobjednika igre u Last 60, kao i hoće li ukupan broj bodova postignut u Last 60 biti veći ili manji od
    11,5 bodova kao kombinirana oklada. (Ovo područje je fiksno na 11,5 i nema varijabilne vrijednosti kao kod nekih od
    gore navedenih područja.) 
  </p>
  <h3>Pobjednik u Last 60 i razlika u broju poena</h3>
  <p>
    Predvidite pobjednika na utakmici u Last 60, kao i razliku u broju postignutih poena, dakle za koliko poena ta
    momčad pobjeđuje na utakmici.
  </p>
</template>
